import {type FavoritesQuery, useFavoritesQuery, useUpdateItemInFavoritesMutation} from '~/graphql/graphql';

type GroupedResult<T> = {
  [key: string]: T[];
};

function groupBy<T>(array: T[], key: keyof T): GroupedResult<T> {
  return array.reduce((result: GroupedResult<T>, currentItem: T) => {
    (result[currentItem[key] as string] = result[currentItem[key] as string] || []).push(currentItem);
    return result;
  }, {});
}

export default function useFavorites() {
  const user = useSofieUserWhenAuthenticated();
  const {result, loading: loadingFavorites, query} = useFavoritesQuery({
    enabled: !!user.value,
  });

  const favorites = computed(() => groupBy<FavoritesQuery['favorites'][number]>(result.value?.favorites ?? [], '__typename'));

  const isFavorite = (id: number, __typename: string) => computed(() => {
    return favorites.value[__typename]?.some(favorite => favorite.id === id) ?? false;
  });

  const toggleFavoriteMutation = useUpdateItemInFavoritesMutation({
    update(cache, {data}) {
      if (data?.updateItemInFavorites) {
        const isCurrentlyFavorite = isFavorite(data.updateItemInFavorites.id, data.updateItemInFavorites.__typename!).value;

        if (isCurrentlyFavorite) {
          removeFromQueryCache(query.value!.options, cache, data.updateItemInFavorites);
        } else {
          addToQueryCache(query.value!.options, cache, data.updateItemInFavorites);
        }
      }
    },
  });

  return {favorites, isFavorite, toggleFavoriteMutation, loadingFavorites};
}
